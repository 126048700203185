.pagination {
  display: flex;
  align-items: center;
  width: max-content;
  margin: 30px auto 10px auto;
  list-style: none;
  user-select: none;
  gap: 20px;
}

.pageItem {
  min-width: 18px;
  min-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2px;
  /* transition: all 0.3s; */
}

.pageItem.active {
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  width: 40px;
  height: 40px;
  border: solid;
  background: #e77996;
  color: white;
  cursor: pointer;
}

.pageItem.disabled {
  cursor: not-allowed;
  color: rgba(155, 155, 155, 0.3);
}

.arrowIcon {
  font-size: 25px;
}