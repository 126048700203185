.detailBodyContainer {
  max-width: 1280px;
  margin: 20px auto;
}

/* .detailContent {
  display: flex;
  align-items: flex-start;
  gap: 20px;
} */

.detailActorContainer {
  flex: 4;
  margin-bottom: 50px;
}

.detailActor {
  display: flex;
  align-items: center;
  gap: 20px;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  margin-bottom: 20px;
}

.detailRecommend {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.detailRecommendPostList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 25px;
  column-gap: 15px;
}

.detailRecommendPostList img {
  /* max-height: 200px; */
  object-fit: contain;
}

.detailActorImage {
  flex: 1;
}

.actorProfile {
  display: flex;
  flex: 2;
  width: 500px;
  height: 280px;
  position: relative;
}

.actorProfileImg {
  width: 90%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  margin: 0 auto;
}

/* .actorProfile img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  margin: 0 auto;
} */

.iCvr {
  filter: blur(5px);
  opacity: 0.8;
  z-index: -1;
  position: absolute;
  top: 0px;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}

.detailActorDesc {
  flex: 3;
}

.detailActorDesc .desc {
  margin: 10px 0px 30px 0px;
}

.detailActorDesc .title {
  font-size: 18px;
}

.detailActorDesc p {
  line-height: 20px;
}

.detailActorDesc .descText,
.detailActorDesc .category,
.detailActorDesc .time {
  color: #808191;
  font-size: 14px;
  margin: 3px 0px;
}

.detailActorDesc .label {
  display: flex;
  align-items: center;
  background: #fb5384cc;
  width: 65px;
  color: #fff;
  border-radius: 50px;
  font-size: 14px;
  justify-content: center;
  margin-bottom: 10px;
  gap: 3px;
}

.detailActorDesc .label img {
  width: 16px;
}

.detailActorDesc .views {
  display: flex;
  align-items: center;
  background: #fb5384cc;
  width: 65px;
  color: #fff;
  border-radius: 50px;
  font-size: 14px;
  justify-content: center;
  gap: 3px;
}

.detailActorDesc .views img {
  width: 16px;
}

.detailPreviewImage {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 25px;
  column-gap: 20px;
}

.detailPreviewImage img {
  width: 100%;
}

.detailPreviewImage .prev,
.detailPreviewImage .next {
  width: 50px;
  opacity: 1;
}

.detailDownloadContainer {
  background: #f7ebef;
  padding: 15px 20px;
  border-radius: 8px;
  border: 1px solid #fca6c9;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.detailDownload {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.downloadAddress {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 10px;
  font-size: 14px;
}

.downloadAddressTitle {
  border-right: 1px solid #d2d2d2;
  padding-right: 10px;
}

.downloadAddressText {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 750px;
  height: 1.8em;
  white-space: nowrap;
}

.downloadAddressText a {
  color: #8e8e8e;
  text-decoration: none;
}

.downloadHD {
  display: inline-block;
  color: #fb5384;
  background: #f7ebef;
  border: 1px solid #fca6c9;
  font-size: 11px;
  padding: 0px 5px;
  margin-right: 3px;
  border-radius: 2px;
}

.downloadButton {
  display: flex;
  gap: 10px;
}

.downloadDetail {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 15px;
}

.docSize,
.docShareDate {
  color: #8e8e8e;
  font-size: 14px;
}

.downloadButtonPrimary {
  border: none;
  outline: none;
  background: #fff;
  color: #fb5384;
  padding: 8px 16px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 4px 4px 0 #dedede40;
  border: 1px solid #fb538440;
}

.downloadButtonSecondary {
  border: none;
  outline: none;
  background: transparent;
  color: #fb5384;
  text-decoration: underline;
  cursor: pointer;
}

.magnetLinkMethod {
  padding: 0px 15px;
  font-weight: 300;
  font-size: 14px;
  color: #8e8e8e;
}

.previewImageContainer {
  margin: 0px 0px 50px 0px;
}

.previewImageTitle {
  margin: 0px 0px 10px 0px;
}
/* .picDetailPreviewImage {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 25px;
  column-gap: 20px;
} */

.picDetailPreviewImage {
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
  margin: 0 auto;
}

.picDetailPreviewImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.picDetailPreviewImageContainer {
  position: relative;
  width: 48%;
}

.picDetailPreviewImage .prev,
.picDetailPreviewImage .next {
  width: 50px;
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  .detailBodyContainer {
    max-width: 720px;
  }
}

@media only screen and (max-width: 480px) {
  .detailBodyContainer {
    padding: 0px 15px;
  }
  /* .detailRecommend {
    display: none;
  } */

  .detailRecommendPostList {
    grid-template-columns: repeat(2, 1fr);
  }

  .detailActor {
    display: block;
  }

  .detailPreviewImage {
    display: block;
  }

  .actorProfile {
    width: 100%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 8px;
  }
  .detailActorDesc {
    padding: 8px;
  }

  .detailActorDesc .category,
  .detailActorDesc .time {
    font-size: 14px;
  }
  .detailActorDesc .desc {
    margin: 10px 0px 10px 0px;
    display: block;
    /* display: flex;
    gap: 20px; */
  }

  .labelViews {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .detailActorDesc .label {
    margin-bottom: 0px;
  }

  .detailPreviewImage .prev,
  .detailPreviewImage .next {
    width: 40px;
  }
  .detailDownloadContainer {
    padding: 0px 5px;
  }

  .detailDownload {
    padding: 5px 10px;
    display: block;
  }

  .downloadAddress {
    gap: 5px;
    font-size: 12px;
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  .downloadAddressTitle {
    padding-right: 0px;
    flex: 1;
  }

  .downloadAddressText {
    flex: 4;
    font-size: 13px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 250px;
    height: 1.5em;
    white-space: nowrap;
  }

  .docSize,
  .docShareDate {
    font-size: 13px;
    display: block;
  }

  .downloadDetail {
    gap: 10px;
    font-size: 12px;
  }

  .downloadButton {
    gap: 5px;
    justify-content: space-between;
  }

  .downloadButtonSecondary {
    font-size: 11px;
  }

  .downloadButtonPrimary {
    font-size: 11px;
    padding: 5px 15px;
    border-radius: 8px;
  }
}
