.homeBodyContainer {
  max-width: 1280px;
  margin: 20px auto;
}

.bannersContainer {
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin: 10px 0px 30px 0px; */
  min-width: 200px;
  width: 100%;
  margin: 0 auto;
}

.bannersImg {
  width: 100%;
  flex: 1;
  border-radius: 12px;
}

.homeDropdownOption {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
}

.homeOptionList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: scroll;
}

.homeOptionList::-webkit-scrollbar {
  width: 10px;
}

.homeOptionList::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px 10px #ddd;
  border: solid 7px transparent;
}

.homeOptionList img {
  width: 145px;
  cursor: pointer;
}

.homeOption {
  padding: 5px 10px;
  font-size: 14px;
  color: #fa528a;
  text-align: center;
  width: 100px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid #fa528a;
  cursor: pointer;
}

.homeOption.active {
  background: #fa528a19;
}

.homeOption:hover {
  background: #fa528a19;
}

.homePostsTitle {
  margin-top: 20px;
  font-weight: 500;
}

.homePosts {
  margin: 15px 0px;
  position: relative;
  min-height: 500px;
}

.homePostsContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 25px;
  column-gap: 15px;
}

.notFound {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.notFound img {
  width: 250px;
}

@media only screen and (max-width: 1024px) {
  .homePostsContainer {
    grid-template-columns: repeat(3, 1fr);
  }

  .homeOptionList {
    gap: 10px;
  }

  .homeOptionList img {
    width: 100px;
  }

  .homePostsTitle {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .homeBodyContainer {
    max-width: 720px;
  }
  .homePostsContainer {
    grid-template-columns: repeat(3, 1fr);
  }

  .homeOptionList {
    gap: 10px;
  }

  .homeOptionList img {
    width: 100px;
  }
}

@media only screen and (max-width: 480px) {
  .homeBodyContainer {
    padding: 0px 15px;
  }
  .homePostsContainer {
    grid-template-columns: repeat(2, 1fr);
  }

  .homeOptionList {
    display: none;
    gap: 10px;
    /* gap: 20px; */
    /* display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 15px; */
    /* column-gap: 10px; */
  }

  .homeOptionList img {
    width: 100px;
  }

  .homeOption {
    margin: 0 auto;
  }
}
