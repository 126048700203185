.backButton {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #bfbfbf;
  margin: 10px 0px 30px 0px;
  cursor: pointer;
}

.backButton img {
  width: 65%;
}

@media only screen and (max-width: 480px) {
  .backButton {
    width: 35px;
    height: 35px;
    margin: 10px 0px 20px 0px;
  }
}
