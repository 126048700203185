.postCard {
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  cursor: pointer;
}
.postProfileVertical {
  position: relative;
  height: 130px;
  display: flex;
}
.postProfile {
  position: relative;
  height: 130px;
  display: flex;
}

.postImage {
  width: 90%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin: 0 auto;
}

.postImageVertical {
  width: 90%;
  height: 100%;
  object-fit: contain;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin: 0 auto;
}

.iCvr {
  filter: blur(5px);
  opacity: 0.8;
  z-index: -1;
  position: absolute;
  top: 0px;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}

.postDesc {
  padding: 5px 8px;
}

.postDesc p {
  font-size: 14px;
  line-height: 1.5em;
  /* height: 3em; */
  overflow: hidden;
}

.postDescTitle {
  min-height: 40px;
}

.postViews {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px 5px 0px;
}

.postViewLabel {
  /* background: rgba(251, 82, 132, 0.6); */
  /* padding: 0px 12px; */
  border-radius: 50px;
  color: #999;
  font-size: 14px;
}
.postViewCheck {
  /* display: flex;
  align-items: center;
  gap: 5px; */
  background: rgba(251, 82, 132, 0.6);
  padding: 0px 12px;
  border-radius: 50px;

  font-size: 14px;
}

.postViewCheck img {
  width: 18px;
}

.postViewCheck p {
  font-size: 14px;
  line-height: 16px;
  font-weight: 100;
  color: #fff;
}
