.m {
    padding: 10px;
    max-width: 1100px;
    margin: auto;
}
.sec {
    line-height: 25px;
    padding-left: 7px;
    text-align: center;
    padding-top: 10px;
    color: #718083;
}

.grey_b {
    background-color: #e0eff2;
    color: #718083;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 10px;
}

.light_g {
    background-color: #eaf5f7;
    color: #718083;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 10px;
}
.lt {
    color: #e77996;
}
.dt {
    color: #e77996;
    cursor: pointer;
}
.dark_title {
    font-weight: 400;
    line-height: 30px;
    font-size: 18px;
    color: #333;
}
.hover:hover {
    color: #e77996;
    cursor: pointer;
}
.light_g {
    display: grid;
    gap: 15px;
}
.f12_b {
    font-size: 12px;
    padding-bottom: 10px;
}
.l10 {
    padding-left: 10px;
}

@media only screen and (max-width: 600px) {
    .dark_title {
        font-size: 14px;
    }
}