.footer {
  background: #333;
  color: #fff;
  line-height: 22px;
  margin-top: 30px;
}

.footerHeader {
  padding: 20px 10%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footerDesc p {
  font-weight: 100;
  line-height: 22px;
  margin: 5px 0px;
}

.footerMenuList {
  display: flex;
  gap: 60px;
  justify-content: center;
}

.menuTitle {
  color: #969696;
  font-weight: 500;
  margin-bottom: 7px;
}

.menuList a,
.menuList p  {
  color: #fa528a;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
}

.footerCopyright {
  display: flex;
  background: #000;
  color: #fff;
  gap: 20px;
  justify-content: center;
  padding-bottom: 15px;
  padding-top: 15px;
}

.footerCopyright a {
  color: #fff;
}

.footerMenuMobileList {
  display: none;
}

.footerMenuTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 480px) {
  .footerMenuList {
    gap: 10px;
  }

  .menuTitle {
    font-size: 14px;
  }

  .footerMenuList {
    display: none;
  }

  .footerMenuMobileList {
    display: block;
  }
  .footerMenuMobileList > div {
    border-top: 0.5px solid #969696;
    border-bottom: 0.5px solid #969696;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }

  .menuTitle {
    margin-top: 10px;
  }

  .footerDesc p {
    font-size: 13px;
  }
}
