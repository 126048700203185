/* @import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@300;400;500;600;700;900&display=swap"); */

* {
  box-sizing: border-box;
}

html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 400;
}

body {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

img {
  -webkit-user-drag: none;
}

.bodyContainer {
  margin-top: 100px;
  min-height: 600px;
  position: relative;
  padding: 0 20px;
}

#ReactSimpleImageViewer .react-simple-image-viewer__slide {
  overflow: scroll;
  align-items: flex-start;
}

#ReactSimpleImageViewer .react-simple-image-viewer__slide img {
  width: 100%;
  height: auto;
  max-width: none;
  max-height: none;
}

@media only screen and (max-width: 480px) {
  .bodyContainer {
    margin-top: 85px;
    padding: 0;
  }
}

/* ::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px 10px #ddd;
  border: solid 7px transparent;
} */
